.chat-contents {
    display: table-row;
    height: 100%;
    .chat-content-wrap {
        height: 100%;
        position: relative;
        width: 100%;
        .chats {
            @include margin-padding(null, 30px 15px);
            .chat-body {
                @include margin-padding(null,0 10px 0 48px);
            }
            .chat-bubble {
                display: block;
                width: 100%;
                float: left;
                margin-bottom: 10px;
                &:first-child{
                    .chat-content {
                        border-top-right-radius: 20px !important;
                    }
                }
                &:last-child{
                    .chat-content {
                        border-bottom-right-radius: 20px !important;
                    }
                }
                .chat-content{
                    .chat-time {
                        color: rgba(0, 0, 0, 0.5);
                        display: block;
                        font-size: $font-size-12;
                    }
                    .attach-list {
                        color: $secondary-400;
                        line-height: 24px;
                        list-style: none;
                        @include margin-padding(0, 5px 0 0);
                        .task-attach-img {
                            @include margin-padding(0 0 20px, null);
                        }
                    }
                    .chat-img-group{
                        p{
                            @include margin-padding(0 0 20px, null);
                        }
                    }
                    p {
                        @include margin-padding(0, null);
                    }
                }

                &:hover{
                    .chat-action-btns {
                        display: block;
                    }
                }
                .chat-action-btns {
                    display: none;
                    ul {
                        list-style: none;
                        @include margin-padding(15px 15px 0 15px, 0);
                        li {
                            display: inline-block;
                            margin-left: 5px;
                            font-size: $font-size-18;
                            a {
                                color: $secondary-600;
                            }
                        }
                    }
                }
                .chat-img-attach {
                    position: relative;
                    float: right;
                    @include rounded(4px);
                    @include margin-padding(0 0 12px 12px, null);
                    .chat-placeholder {
                        background: rgba(69, 81, 97, 0.6);
                        @include rounded(4px);
                        color: $white;
                        height: 100%;
                        width: 100%;
                        @include position(absolute,0,null,null,0);
                        -ms-flex-direction: column;
                        flex-direction: column;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        justify-content: flex-end;
                        -webkit-justify-content: flex-end;
                        -ms-flex-pack: flex-end;
                        @include margin-padding(null, 13px);
                        .chat-img-name {
                            font-size: $font-size-12;
                            font-weight: $font-weight-bold;
                            line-height: 16px;
                        }
                        .chat-file-desc {
                            font-size: $font-size-11;
                        }
                    }
                }
            }
            .chat-right {
                .chat-body {
                    @include margin-padding(null, 0 10px 0 48px );
                    .chat-content {
                        background-color: $light-400;
                        border: 1px solid $light-900;
                        clear: both;
                        color: $secondary-700;
                        display: block;
                        float: right;
                        max-width: 60%;
                        position: relative;
                        @include margin-padding(0, 8px 15px);
                        @include rounded(20px 2px 2px 20px);
                        .chat-time {
                            text-align: right;
                        }
                        &.img-content {
                            background-color: transparent;
                            border: 0;
                            color: $secondary-600;
                            padding: 0;
                            text-align: right;
                        }
                       
                    }
                    .chat-time {
                        text-align: right;
                    }
                    .chat-action-btns {
                        float: right;
                    }
                }
            }
            .chat-left{
                width: 100%;
                float: left;
                .chat-avatar {
                    float: left;
                    .avatar {
                        line-height: 30px;
                        height: 30px;
                        width: 30px;
                    }
                }
                .chat-body {
                    @include margin-padding(0, 0 20px 0 0);
                    display: block;
                    overflow: hidden;
                }
                .chat-content {
                    background-color: $white;
                    border: 1px solid $secondary-100;
                    color: $secondary-600;
                    float: left;
                    position: relative;
                    max-width: 60%;
                    @include margin-padding(null,8px 15px);
                    @include rounded(2px 20px 20px 2px);
                    @include respond-below(custom575) {
                        max-width:80%;
                    }
                }
                .chat-bubble{
                    &:first-child{
                        .chat-content {
                            border-top-left-radius: 20px;
                        }
                    }
                    &:last-child{
                        .chat-content {
                            border-bottom-left-radius: 20px;
                        }
                    }
                }
                .chat-img-attach {
                    float: left;
                    @include margin-padding(0 12px 12px 0,null);
                }
            }
        }
    }
}
.chat-sidebar{
    .chat-line {
        .chat-date{
            background-color: $white;
            top: 10px;
        }
    }
}
.chat-line {
    border-bottom: 1px solid $secondary-100;
    position: relative;
    text-align: center;
    width: 100%;
    @include margin-padding(7px 0 20px, null);
    .chat-date {
        background-color: $body-bg;
        color: $secondary-700;
        font-size: $font-size-12;
        @include margin-padding(null, 0 11px);
        @include position(relative,7px,null,null,-15px);
    }
}
.chat-main-row {
    overflow: auto;
    padding-bottom: inherit;
    padding-top: inherit;
    @include position(absolute,0,0,0,0);
    .chat-main-wrapper {
        display: table;
        height: 100%;
        table-layout: fixed;
        width: 100%;
        .message-view {
            display: table-cell;
            height: 100%;
            float: none;
            position: static;
            vertical-align: top;
            width: 75%;
            @include margin-padding(null, 0);
            @include respond-below(custom991) {
                width: 100%;
            }
            
        }
        .chat-sidebar {
            background-color: $white;
            border-left: 1px solid $secondary-100;
            width: 300px !important;
           
        }
    }
}
.chat-sidebar {
    .chat-contents {
        background-color: $white;
        .chats {
            @include margin-padding(null, 15px 15px 30px);
            .chat-left {
                @include margin-padding(0 0 20px, null);
                .chat-avatar {
                    float: left;
                    .avatar {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                    }
                }
                .chat-body {
                    @include margin-padding(0 0 0 50px, 0);
                    .chat-bubble {
                        display: block;
                        width: 100%;
                        float: left;
                        @include margin-padding(0 0 10px, null);
                        .chat-content {
                            border: 0;
                            padding: 0;
                            background-color: transparent;
                            max-width: 100%;
                            @include rounded(0 !important);
                            .task-chat-user {
                                color: $secondary-900;
                                font-size: $font-size-13;
                            }
                            .chat-time {
                                color: $secondary-600;
                                display: inline-block;
                                font-size: $font-size-11;
                            }
                            .call-details {
                                @include margin-padding(10px 0 0px, null);
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                .call-info {
                                    margin-left: 10px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.video-window {
    .fixed-header {
        padding: 0 !important;
        border: 0;
        .nav {
            li{
                a{
                    @include margin-padding(null, 18px 15px);
                }
            }
        }
    }
}
.chat-profile-img {
    padding: 30px;
    position: relative;
    text-align: center;
    .edit-profile-img {
        height: 120px;
        margin: 0 auto;
        position: relative;
        width: 120px;
        cursor: pointer;
        img {
            height: auto;
            margin: 0;
            width: 120px;
            @include rounded(50%);
        }
        .change-img {
            background-color: rgba(0, 0, 0, 0.3);
            color: $white;
            display: none;
            height: 100%;
            line-height: 120px;
            width: 100%;
            @include position(absolute,0,null,null,0);
            @include rounded(50%);
        }
        &:hover{
            .change-img {
                display: block !important;
            }
        }
    }
    .edit-btn {
        height: 36px;
        width: 36px;
        @include position(absolute,15px,15px,null,null);
        @include rounded(40px);  
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        i {
        font-size: 13px;
        }         
    }
}
.files-list {
    list-style: none;
    padding-left: 0;
   > li {
        border-bottom: 1px solid $secondary-100;
        @include margin-padding(2px 0 0, 10px);
        @include rounded(inherit);
        .files-cont {
            position: relative;
            .file-type {
                height: 48px;
                position: absolute;
                width: 48px;
                .files-icon {
                    background-color: $light-300;
                    border: 1px solid $light;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    width: 38px;
                    @include rounded(4px);
                    i {
                        color: $secondary-600;
                        font-size: $font-size-20;
                    }
                }
            }
            .files-info {
                @include margin-padding(null, 0 30px 0 50px);
                .text-ellipsis {
                    display: block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .file-author {
                    a {
                        color: $primary;
                        font-size: $font-size-12;
                        text-decoration: underline;
                    }
                    .file-date {
                        color: $secondary-600;
                        font-size: $font-size-12;
                    }
                }
            }
            .files-action {
                display: none;
                height: 30px;
                list-style: none;
                padding-left: 0;
                text-align: right;
                width: 30px;
                @include position(absolute,0,0,null,null);
                a {
                    color: $secondary-600;
                }
            }
        }
        &:hover{
            .files-action {
                display: block;
            }
        }
    }
}
.search-box {
    margin-top: 5px;
    @include respond-below(custom991) {
        display: none;
    }
    .input-group {
        background-color: $body-bg;
        border: 1px solid $light-900;
        @include rounded(50px);
        .form-control {
            background: transparent;
            border: none;
        }
        .btn {
            background-color: transparent;
            border: 0;
        }
    }
}
.upload-drop-zone {
    background-color: $white;
    border: 2px dashed $light-900;
    color: $secondary-200;
    height: 200px;
    line-height: 200px;
    margin-bottom: 20px;
    text-align: center;
    .upload-text {
        font-size: 24px;
        margin-left: 10px;
    }
}
.upload-list {
    padding: 0;
    margin: 0;
    list-style: none;
    .file-size {
        color: $secondary-600;
    }
    .file-list{
        background-color: $white;
        border-top: 1px solid $light-900;
        padding: 10px 0;
        &:first-child {
            border-top: none;
        }
    }
    .upload-wrap {
        position: relative;
        @include margin-padding(0 0 5px, 0 20px 0 0);
    }
    .file-name {
        padding-right: 15px;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        color: $secondary-600;
        margin: 0 5px 0 0;
        vertical-align: middle;
    }
    .file-close {
        border: none;
        background: none;
        color: $secondary-200;
        @include position(absolute,2px,0,null,null);
    }
    .progress {
        margin-bottom: 5px;
    }
    .upload-process {
        font-size: $font-size-10;
        color: $secondary-600;
    }
}