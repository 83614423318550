.mto_table td{
    white-space: normal !important;
}

.btn-expense{
    background-color: rgba(253, 112, 92, 1);
    color: white;
    border:rgba(253, 112, 92, 1);
    padding: 0% 2%;
}
