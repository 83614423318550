@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
.settings-icon {
    z-index: 99999;
    @include position(fixed,null,20px,20px,null);
	@include respond-below(custom991) {
		display: none;
	}
    span {
    	width: 45px;
    	height: 45px;
    	border-radius: 45px;
    	cursor: pointer;
    	color: $white;
    	font-size: $font-size-24;
    	background-color: $primary;
    	-webkit-animation:spin 4s linear infinite;
	    -moz-animation:spin 4s linear infinite;
	    animation:spin 4s linear infinite;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
    }
}