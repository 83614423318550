.nav-tabs {
    border-bottom: 1px solid $light;
    &.nav-tabs-bottom {
        li {
            margin-bottom: -1px;
        }
    }
    .nav-link {
        margin-bottom: -1px;
        background: 0 0;
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    li{
        a{
            color: $secondary-600;
            border-bottom-width: 2px;
            &.active{
                border-color: transparent;
                border-bottom-color: $primary;
                background-color: transparent;
                @include transition(all 0.2s ease);
            }
        }
    }
}
.content-full {
    height: 100%;
    position: relative;
    width: 100%;
    .display-table {
        display: table;
        table-layout: fixed;
        border-spacing: 0;
        width: 100%;
        height: 100%;
        .table-row {
            display: table-row;
            height: 100%;
            .table-body {
                position: relative;
                height: 100%;
                width: 100%;
                .table-content {
                    bottom: 0;
                    left: 0;
                    overflow: auto;
                    @include position(absolute,0,0,null,null);
                    .chat-profile-img {
                        padding: 30px;
                        position: relative;
                        text-align: center;
                        .edit-profile-img {
                            height: 120px;
                            margin: 0 auto;
                            position: relative;
                            width: 120px;
                            cursor: pointer;
                            img {
                                height: auto;
                                margin: 0;
                                width: 120px;
                                @include rounded(50%);
                            }
                            .change-img {
                                background-color: rgba(0, 0, 0, 0.3);
                                color: $white;
                                display: none;
                                height: 100%;
                                line-height: 120px;
                                width: 100%;
                                @include rounded(50%);
                                @include position(absolute,0,null,null,0);
                            }
                            .edit-btn {
                                height: 36px;
                                width: 36px;
                                @include rounded(40px);
                                @include position(absolute,15px,15px,null,null);
                            }
                        }
                        .user-name {
                            color: $secondary-900;
                        }
                    }
                    .chat-profile-info {
                        @include margin-padding(null, 15px);
                        .user-det-list {
                            list-style: none;
                            padding: 0;
                            li {
                                @include margin-padding(null, 6px 15px);
                            }
                        }
                    }
                }
            }
        }
    }
}
.nav-tabs{
    .nav-tabs-solid {
        background-color: $light-100;
        border: 0;
        .active{
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
}
.nav-tabs{
    .nav-link{
        &:hover{
            background-color: $light-600;
            border-color: transparent;
            color: $secondary-900;
        }
    }
    &.nav-tabs-top{
        li{
            a{
                border-width: 2px 0 0 0;
                &.active{
                    border-top-color: $primary;                    
                    @include rounded(0);
                }
            }
        }
    }
    &.nav-tabs-bottom{
        li{
            a{
                border-width:0 0 2px  0;
                &.active{
                    border-bottom-color: $primary;
                    background: transparent;
                    @include rounded(0);
                }
            }
        }
    }
    &.nav-tabs-solid{
        background-color: $light-100;
        border: 0;
        &.nav-tabs-rounded{
            li{
                a{
                    &.active{
                        background-color: $primary;
                        border-color: $primary;
                        color: $white;
                        @include rounded(50px);
                    }
                }
            }  
        }
        li{
            a{
                &.active{
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
                    @include rounded(0);
                }
            }
        }   
    }
}
.page-menu{
    .nav-tabs {
        li{
            a{
                font-size: $font-size-16;
            }
        }
    }
}
@include respond-below(custom768) {
    .nav-tabs {
        border-bottom: 0;
        position: relative;
        background-color: $white;
        border: 1px solid $light;
        @include margin-padding(null, 5px 0);
        @include rounded(3px);
    }
}
.nav.nav-style-1 {
  border: 0;
  .nav-item {
    margin-inline-end: 0.25rem;
    &:last-child {
      margin-inline-end: 0;
    }
  }
  .nav-link {
    &.active {
        background-color: rgba($primary, 0.1);
        color: $primary;
        &:hover {        
          background: rgba($primary, 0.1);
        }
    }
    &:hover {        
        background: transparent;
        color: $primary;
    }
  }
}
.nav.nav-style-2 {
  .nav-item .nav-link {
    border-block-start: 3px solid transparent;
    &.active {
      background-color: rgba($primary, 0.1);
      border-block-start: 3px solid $primary;
      color: $primary;
    }
    &:hover {
        color: $primary;
    }
  }
}
.nav.nav-style-3 {
  .nav-link {
    border-radius: 0;
  }
  .nav-link {
    border-block-end: 3px solid transparent;
    &:hover {
        color: $primary;
    }
  }
  .nav-link.active {
    background-color: transparent;
    border-block-end: 3px solid $primary;
    color: $primary;
  }
}
.nav.nav-style-4 {
  border: 0;
  .nav-link {
    border: 0;
    border-radius: 4px;
  }
  .nav-link {
    margin-bottom: 3px;
    &.active {
      background-color:$light;
      border: 0;
    }
    &:hover,
    &:focus {
      border: 0;
    }
    &:hover {
        background-color:$light;
    }
  }
}
.nav-tabs.nav-style-5 {
  border: 0;
  .nav-link {
    border: 0;
    color: $secondary-600;
    margin-bottom: 3px;
    border-radius: 4px;
  }
  .nav-link {
    &.active {
      background-color: $primary;
      color: $white;
      border: 0;
    }
    &:hover,
    &:focus {
      border: 0;
      background-color: $primary;
      color: $white;
    }
  }
}
.nav-style-6 {
  border: 0;
  &.nav-pills .nav-link {
    &.active {
      border: 1px solid $primary;
      border-block-end: 2px solid $primary;
      background-color: transparent;
      color: $primary;
    }
  }
}
.nav-tabs-header {
  border: 0 !important;
  .nav-item {
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link {
      border: 1px solid transparent;
      font-size: 0.8rem;
      padding: 0.4rem 0.5rem;
      font-weight: $font-weight-medium;
      &.active {
        background: rgba($primary, 0.1);
        color: $primary;
        border: 1px solid transparent;
      }
      &:hover,&:focus {
        border: 1px solid transparent;
        background-color: transparent;
        color: $primary;
      } 
    }
  }
}
.nav-tabs-footer {
  border: 0;
  .nav-item {
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link {
      background-color: transparent;
      color: $primary;
      border: 1px solid $primary;
      border-radius: 4px;
      font-size: 0.8rem;
      &.active {
        background: $primary;
        color: $white;
        border: 1px solid $primary;
      }
      &:hover,&:focus {
        border: 1px solid $primary;
        background-color: transparent;
         color: $primary;
      } 
    }
  }
}
.vertical-tabs-2 {
  border: 0;
  .nav-item {
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link {
      min-width: 7.5rem;
      max-width: 7.5rem;
      text-align: center;
      border: 1px solid $light;
      margin-bottom: 0.5rem;
      background-color:$light;
      i {
        font-size: 1rem;
      }
      &.active {
        background-color: $primary;
        color: $white;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          inset-inline-end: -0.5rem;
          inset-block-start: 38%;
          transform: rotate(45deg);
          width: 1rem;
          height: 1rem;
          background-color: $primary;
        }
        &:hover {
          color: $white;
        }
      }
      &:hover {
        color: $primary;
      }
    }
  }
}
.vertical-tabs-3 {
  border: 0;
  .nav-item {
    border: 0;
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link {
      min-width: 160px;
      max-width: 160px;
      border: 1px solid $primary;
      padding: 1.3rem;
      margin-block-end: 0.5rem;
      border-radius: 4px;
      i {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 1rem;
        background: $primary;
        color: $white;
        border-radius: 3.125rem;
        font-weight: $font-weight-medium;
      }
      &.active {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
        &:hover {
          background-color: $primary;
          border: 1px solid $primary;
          color: $white;          
        }
        i {
          background: $white;
          color: $primary;
        }
      }
      &:hover,&:focus {
        border: 1px solid $primary;
        color: $primary;
          background: transparent;
      }
    }
  }
}
.nav.tab-style-1 {
  border-block-end: 0;
  padding: 0.65rem;
  background-color:$light;
  border-radius: 7px;
  margin-block-end: 1rem;
  .nav-item {
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
  }
  .nav-link {
    border-radius: 7px;
    padding: 0.35rem 1rem;
    &.active {
      background-color: $primary !important;
      color: $white !important;
      box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
      transition: color 0.15s ease-in;
      border: 0;
    }
  }
}
.nav-tabs.tab-style-1 {
    .nav-link {
        &:hover {
        background: transparent;
        color: $primary;
      }  

    }

}
.tab-style-2 {
  border-block-end: 0;
  .nav-item {
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link {
      color: $secondary-600;
      border: 0;
      i { 
        width: 1.875rem;
        height: 1.875rem;
        padding: 0.4rem;
        border-radius: 50px;
        background-color:$light;
        display: inline-block;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        border: 0;
        background: transparent;
        color: $primary;
        i {
          color: $primary;          
        }
      }
      &.active {
        background-color: transparent;
        position: relative;
        border: 0;
        color: $primary;
        i { 
          background-color: rgba($primary, 0.1);
          color: $primary;
        }
        &:before {
          content: "";
          position: absolute;
          inset-inline-start: 0;
          inset-inline-end: 0;
          inset-block-end: 0;
          width: 100%;
          height: 0.175rem;
          background-color: $primary;
          border-radius: 50px;
        }
      }
    }
  }
}
.tab-style-3 {
  border-block-end: 0;
  .nav-item {
    position: relative;
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link {
      position: relative;
      border: 0;
      font-size: 0.75rem;
      color: $secondary-600;
      &:before {
        position: absolute;
        content: "";
        inset-block-end: -1.063rem;
        inset-inline-end: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 0.1rem;
        background-color:$light;
        border-radius: 50%;
      }
      &:after {
        position: absolute;
        content: "";
        inset-block-end: -2rem;
        inset-inline-start: 1.75rem;
        inset-inline-end: 0;
        inset-inline-start: 30%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 50%;
        border: 2px solid $light;
      }
      &.home:after {
        content: "1";
      }
      &.about:after {
        content: "2";
      }
      &.services:after {
        content: "3";
      }
      &:hover {
        border: 0;
        background: transparent;
        color: $primary;
      }
      &.active {
        background-color: transparent;
        border: 0;
        color: $primary;
        &:before {
          background-color: $primary;
        }
        &:after {
          border-color: $primary;
          color: $primary;
        }
      }
    }
  }
}
.tab-style-4 {
  &.nav-tabs {
    border: 0;
    .nav-item {
      margin-inline-end: 0.5rem;
      &:last-child {
        margin-inline-end: 0;
      }
    }
    .nav-link {
      border: 0;
      color: $secondary-600;
      &:hover {
        border: 0;
        background: transparent;
        color: $primary;
      }
      &.active {
        background-color: transparent;
        border: 0;
        color: $primary;
        position: relative;
        overflow: hidden;
        &::before {
          position: absolute;
          content: "";
          width: 0.75rem;
          inset-block-start: 31px;
          transform: rotate(45deg);
          inset-inline-start: 47%;
          overflow: hidden;
          z-index: 1;
          height: 0.75rem;
          background-color: $primary;
          border: 1px solid $primary;
        }
        &:after {
          position: absolute;
          content: "";
          width: 40%;
          height: 1px;
          inset-inline-start: 30%;
          inset-block-end: 0;
          background-color: $primary;
        }
      }
    }
  }
}
#tab-style-4 .tab-pane.active {
  border: 1px solid $primary;
  background-color: $primary;
  color: $white;
  padding: 16px;
    border-radius: 7px;
}
.tab-style-5 {
  .nav-item {
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link.active {
      background: $primary;
    }
  }
}
.nav-pills {
    .nav-link {
         color: $secondary-600;
    }
}
.tab-style-6 {
  border: 0;
  background-color: $white;
  border-radius: 7px;
  color: $primary;
  padding: 0.5rem;
  .nav-item {
    border: 0;
    margin-inline-end: 0.5rem;
    &:last-child {
      margin-inline-end: 0;
    }
    .nav-link {
      color: $primary;
      padding: 0.5rem 1rem;
      font-size: 0.813rem;
      border: 0;
      font-weight: $font-weight-medium;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      border-radius: 4px;
      &.active {
        border: 0;
        background-color: $primary;
        color: $white;
        border: 0;
        box-shadow: 0px 3px 10px 0px rgba(var(--dark-rgb),0.05);over,
          &:hover,&:focus {
            background-color: $primary;
            border: 0;
            color: $white;;
          }
      }
      &:hover,
      &:focus {
        background: transparent;
        border: 0;
        color: $primary;;
      }
    }
  }
}
.bg-info-transparent {
    background-color: rgba($info, 0.1) !important;
    color: $info !important;
    &:hover {
        background-color: rgba($info, 0.1) !important;
        color: $info !important;
    }
}
.tab-style-7 {
  .nav-link {
    margin-block-end: 0.5rem;
    font-size: 0.813rem;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    &:active,
    &.active {
      background-color: rgba($primary, 0.1);
      color: $primary;
    }
    &:hover {
        color: $primary;
    }
  }
}
.nav-style-1 {
  .nav-item {
    .nav-link {
      &.active {
        background-color: rgba($primary, 0.1);
        color: $primary;
      }
    }
  }
}
@media screen and (max-width:400px) {
  .tab-style-4 {
    .nav-link{
      padding-inline: 0.5rem;
    }
  }
}