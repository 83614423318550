/* Calendar Container */
.fc {
  max-width: 100%;
  margin: 0 auto;
  font-size: 1em;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  padding: 24px;
}

/* Header Toolbar */
.fc .fc-toolbar {
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
}

.fc .fc-toolbar h2 {
  font-size: 1.75em;
  margin: 0;
  font-weight: 600;
  color: #1a1f36;
}

/* Calendar Buttons */
.fc .fc-button {
  background-color: #ffffff;
  border: 1.5px solid #e0e0e0;
  color: #4a5568;
  padding: 0.6em 1.2em;
  font-size: 0.95em;
  line-height: 1.5;
  border-radius: 8px;
  margin: 0 0.3em;
  transition: all 0.2s ease;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.fc .fc-button:hover {
  background-color: #f8fafc;
  border-color: #cbd5e1;
  color: #1a1f36;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
}

.fc .fc-button-primary {
  background-color: #3b82f6;
  border-color: #3b82f6;
  color: #ffffff;
}

.fc .fc-button-primary:hover {
  background-color: #2563eb;
  border-color: #2563eb;
}

.fc .fc-button-active {
  background-color: #1d4ed8 !important;
  border-color: #1d4ed8 !important;
  color: #ffffff !important;
}

/* Calendar Events */
.fc-event {
  border: none;
  padding: 4px 8px;
  margin: 2px 0;
  cursor: pointer;
  border-radius: 6px;
  font-size: 0.9em;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fc-event:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

/* Modern Event Colors */
.bg-success {
  background-color: #10b981 !important;
  color: #ffffff;
}

.bg-info {
  background-color: #0ea5e9 !important;
  color: #ffffff;
}

.bg-primary {
  background-color: #8b5cf6 !important;
  color: #ffffff;
}

.bg-purple {
  background-color: #d946ef !important;
  color: #ffffff;
}

/* Calendar Grid */
.fc-day-grid-container {
  height: auto !important;
}

.fc td,
.fc th {
  border: 1px solid #e5e7eb;
  padding: 12px;
}

.fc th {
  font-weight: 600;
  color: #4a5568;
  text-transform: uppercase;
  font-size: 0.85em;
  letter-spacing: 0.05em;
}

/* Today Highlight */
.fc .fc-day-today {
  background-color: #f0f9ff !important;
  position: relative;
}

.fc .fc-day-today::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #3b82f6;
  pointer-events: none;
}

/* Weekend Days */
.fc-day-sat,
.fc-day-sun {
  background-color: #fafafa;
}

/* Hover effect on days */
.fc-day:hover {
  background-color: #f8fafc;
}

/* More Events Indicator */
.fc-more {
  color: #6b7280;
  font-size: 0.85em;
  font-weight: 500;
  padding: 2px 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .fc {
    padding: 16px;
    border-radius: 8px;
  }

  .fc .fc-toolbar {
    flex-direction: column;
    gap: 1em;
    padding: 0;
  }

  .fc .fc-toolbar-title {
    font-size: 1.2em;
  }

  .fc .fc-button {
    padding: 0.5em 1em;
    font-size: 0.9em;
  }

  .fc td,
  .fc th {
    padding: 8px;
  }
}

/* Animation for events */
@keyframes eventFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fc-event-main {
  animation: eventFadeIn 0.3s ease-out;
  cursor: pointer;
  padding: 2px 4px;
}

/* View Toggle Styles */
.view-toggle {
  margin-right: 15px;
}

.view-toggle .ant-tabs-nav {
  margin-bottom: 0;
}

.view-toggle .ant-tabs-tab {
  padding: 8px 16px;
}

/* Event List Styles */
.event-list {
  padding: 20px;
}

.event-list-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 8px;
  color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.event-list-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.event-time {
  font-size: 0.9em;
  opacity: 0.9;
  margin-bottom: 4px;
}

.event-title {
  font-weight: 500;
  font-size: 1.1em;
}

/* Responsive adjustments for view toggle */
@media (max-width: 768px) {
  .view-toggle {
    margin-bottom: 15px;
  }

  .col-auto.float-end {
    flex-direction: column;
    align-items: stretch !important;
  }

  .btn.add-btn {
    margin-top: 10px;
  }
}

/* Table View Styles */
.events-table {
  margin: 20px;
}

.event-title {
  font-weight: 500;
  color: #1a1f36;
}

/* Table Styles */
.events-table .ant-table-thead > tr > th {
  background-color: #f8f9fa;
  color: #1a1f36;
  font-weight: 600;
  padding: 16px;
}

.events-table .ant-table-tbody > tr > td {
  padding: 16px;
}

.events-table .ant-table-tbody > tr:hover > td {
  background-color: #fafafa;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .events-table {
    margin: 10px;
  }

  .events-table .ant-table-thead > tr > th,
  .events-table .ant-table-tbody > tr > td {
    padding: 12px;
  }
}

/* Holiday and Weekend Events */
.holiday-event {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  color: white !important;
}

.weekend-event {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
  color: white !important;
  opacity: 0.8;
}

/* Make sure events are visible */
.fc-daygrid-event {
  white-space: nowrap;
  border-radius: 3px;
  padding: 2px 4px;
  margin: 1px 2px;
  font-size: 0.85em;
}

/* Ensure weekend events don't get hidden */
.fc-daygrid-day-events {
  min-height: 2em;
}

/* Month Selector */
.ant-select-selector {
  border-radius: 6px !important;
}

/* Style the tooltip */
.ant-tooltip-inner {
  min-width: 200px;
  text-align: left;
}

.holiday-event-content {
  padding: 2px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.holiday-tooltip {
  max-width: 300px;
}

.holiday-tooltip .ant-tooltip-inner {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.holiday-tooltip .ant-tooltip-inner p {
  margin-bottom: 4px;
}

.holiday-tooltip .ant-tooltip-inner p:last-child {
  margin-bottom: 0;
}

.holiday-tooltip .ant-tooltip-arrow-content {
  background-color: #fff;
}

.fc-event-main {
  position: relative;
}

/* Style the tooltip */
.ant-tooltip {
  max-width: 200px;
}

.ant-tooltip-inner {
  padding: 6px 12px;
  min-height: 32px;
  text-align: center;
}

/* Event styling */
.holiday-event-wrapper {
  width: 100%;
  padding: 2px 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.holiday-event-content {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: rgba(220, 53, 69, 0.1);
  transition: all 0.2s ease;
}

.holiday-event-content:hover {
  background-color: rgba(220, 53, 69, 0.2);
}

.holiday-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #dc3545;
  flex-shrink: 0;
}

.holiday-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85rem;
  color: #dc3545;
}

/* Modal styling */
.holiday-details {
  text-align: center;
}

.holiday-details h3 {
  color: #dc3545;
  margin-bottom: 12px;
  font-size: 1.2rem;
  font-weight: 600;
}

.holiday-details .holiday-date {
  color: #666;
  font-size: 1rem;
}

/* Calendar event overrides */
.fc-event {
  border: none !important;
  background: transparent !important;
  margin: 1px 0 !important;
}

.fc-daygrid-event-harness {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

/* Modal animation */
.ant-modal {
  transform-origin: center !important;
}

.ant-modal-content {
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

/* Responsive tabs styling */
.holiday-tabs {
  min-width: 200px;
  margin-left: auto;
}

.holiday-tabs .ant-tabs-nav {
  margin: 0 !important;
}

.holiday-tabs .ant-tabs-nav::before {
  display: none;
}

.holiday-tabs .ant-tabs-tab {
  padding: 8px 16px;
  margin: 0 !important;
}

.tab-label {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .holiday-tabs {
    width: 100%;
    margin: 10px 0;
  }

  .holiday-tabs .ant-tabs-nav {
    width: 100%;
  }

  .holiday-tabs .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;
  }

  .holiday-tabs .ant-tabs-tab {
    flex: 1;
    text-align: center;
    justify-content: center;
  }
}

/* Page header responsive adjustments */
.page-header .row {
  margin: 0;
}

.page-title {
  margin-bottom: 0;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .page-header {
    padding: 15px;
  }

  .page-title {
    font-size: 1.25rem;
  }
}

.calendar-wrapper {
  position: relative;
  min-height: 500px;
}

.ant-spin-nested-loading {
  height: 100%;
}

.custom-placeholder::placeholder {
  font-size: 12px;
}
/* .card-career{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
} */