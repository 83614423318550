.mailview-header {
    border-bottom: 1px solid $light;
    @include margin-padding(0 0 20px, 0 0 15px);
    .mail-view-title {
        font-weight:$font-weight-medium;
        font-size: $font-size-24;
    }
    .mail-view-action {
        float: right;
    }
    .clearfix {
        margin-top: 10px;
    }
}
.sender-info{
    .sender-img {
        float: left;
        margin-right: 10px;
        width: 40px;
    }
    .sender-name {
        display: block;
    }
    .mail-sent-time {
        float: right;
    }
}
.attachments {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        border: 1px solid $light-600;
        float: left;
        width: 200px;
        @include margin-padding(0 0 10px 10px, null);
        .attach-file {
            color: $secondary-600;
            font-size: $font-size-60;
            text-align: center;
            min-height: 153px;
            @include margin-padding(null, 10px);
            i {
                line-height: 133px;
            }
        }
        .attach-info {
            background-color: $light-400;
            @include margin-padding(null, 10px);
            .attach-filename {
                color: $secondary-600;
                font-weight: $font-weight-bold;
            }
        }
    }
}
.mailview-footer {
    border-top: 1px solid $light;
    @include margin-padding(20px 0 0, 15px 0 0);
    @include respond-below(custom575) {
        .left-action {
            text-align: center;
            margin-bottom: 15px;
        }
    }
    .right-action {
        text-align: right;
        @include respond-below(custom575) {
            text-align: center;
        }
    }
}