.card-career {
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  padding: 2.5%;
}
.card-career h5 {
  margin-top: 3%;
}
.career-list {
  list-style-type: disc;
  margin-left: 20px;
}
.table-with-spacing {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  text-align: left;
}

.table-with-spacing td {
  border: 1px solid #ccc;
}

.table-with-spacing th {
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #f8f8f8;
}
.table-left {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  width: 15%;
  text-align: center;
  padding: 10px 10px;
}
.table-right {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 12px;
}
.filter-btn {
  border-radius: 22px !important;
  padding: 2px 10px !important;
}
.card-weekly {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  /* height: 80%; */
} 
.status-indicator {
  width: 27px;
  height: 27px;
  border: 1px solid gray;
  border-radius: 50%; 
  position: absolute;
  top: 23px;
  left: 12px;
}
.card-weekly:hover {
  box-shadow: 0 4px 8px 0 rgba(126, 126, 126, 0.2), 0 6px 20px 0 rgba(107, 107, 107, 0.19);
}
.comment-container {
  position: relative;
  max-height: 4.5em; 
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.comment-container .fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5em;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.comment-container span {
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  padding-left: 5px;
  cursor: pointer;
}
.modal-weekly{
  background-color: white;
}