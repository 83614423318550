.border-container {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: 0.25rem;
    background-color: #f8f9fa;
}
.border {
    border: 1px solid #dbe0e6 !important;
    &.border-1 {
        border-width: 1px !important;
    }
    &.border-2 {
        border-width: 2px !important;
    }
    &.border-3 {
        border-width: 3px !important;
    }
    &.border-4 {
        border-width: 4px !important;
    }
    &.border-5 {
        border-width: 5px !important;
    }
    &.border-secondary {
        border-color: $secondary !important;
    }
    &.border-success {
        border-color: $success !important;
    }
    &.border-warning {
        border-color: $warning !important;
    }
    &.border-info {
        border-color: $info !important;
    }
    &.border-danger {
        border-color: $danger !important;
    }
    &.border-light {
        border-color: #f8f9fa !important;
    }
    &.border-dark {
        border-color: $black !important;
    }
    &.border-block-start-dashed {
        border-top-style: dashed;
    }
    &.border-block-start-dotted {
        border-top-style: dotted;
    }
    &.border-inline-end-dashed {
        border-inline-end-style: dashed;
    }
    &.border-inline-end-dotted {
        border-inline-end-style: dotted;
    }
    &.border-block-end-dashed {
        border-bottom-style: dashed;
    }
    &.border-block-end-dotted {
        border-bottom-style: dotted;
    }
    &.border-inline-start-dashed {
        border-inline-start-style: dashed;
    }
    &.border-inline-start-dotted {
        border-inline-start-style: dotted;
    }
    &.border-0 {
        border: 0 !important;
    }
}
.border.border-start-0 {
    border-inline-start: 0 !important;
}
.bg-info.bg-opacity-10 {
    background-color: rgba(23, 162, 184, 0.1) !important;
}
.border.border-top-0 {
    border-block-start: 0 !important;
}
.border.border-end-0 {
    border-inline-end: 0 !important;
}
.border.border-bottom-0 {
    border-block-end: 0 !important;
}
.border.border-start-0 {
    border-inline-start: 0 !important;
}
.br-1 {
    border-radius: 0.0625rem;
}
.br-2 {
    border-radius: 0.125rem;
}
.br-3 {
    border-radius: 0.1875rem;
}
.br-4 {
    border-radius: 0.25rem;
}
.br-5 {
    border-radius: 0.3125rem;
}
.br-6 {
    border-radius: 0.375rem;
}
.br-7 {
    border-radius: 0.4375rem;
}
.br-8 {
    border-radius: 0.5rem;
}
.br-9 {
    border-radius: 0.5625rem;
}
.br-10 {
    border-radius: 0.625rem;
}
.br-15 {
    border-radius: 0.9375rem;
}
.br-20 {
    border-radius: 1.25rem;
}
.border-opacity-75 {
    --bs-border-opacity: 0.75;
}
.border-opacity-50 {
    --bs-border-opacity: 0.5;
}
.border-opacity-25 {
    --bs-border-opacity: 0.25;
}
.border-opacity-10 {
    --bs-border-opacity: 0.1;
}
.border.border-success.border-opacity-10 {
    border-color: rgba(40, 199, 111, 0.1) !important;
}
.border.border-success.border-opacity-25 {
    border-color: rgba(40, 199, 111, 0.25) !important;
}
.border.border-success.border-opacity-50 {
    border-color: rgba(40, 199, 111, 0.5) !important;
}
.border.border-success.border-opacity-75 {
    border-color: rgba(40, 199, 111, 0.75) !important;
}
.bd-placeholder-img {
    margin: 0.125rem;
}
.border-pg {
    img {
        &.avatar {
            border-radius: 4px;
        }
    }
}