.custom-tabs {
  flex-grow: 1;
}

.custom-tabs .nav-tabs {
  border-bottom: none;
}

.custom-tabs .nav-link {
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  margin-right: 5px;
}

.custom-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.custom-tabs .tab-content {
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 16px;
}

.btn-secondary,
.btn-outline-secondary {
  transition: all 0.3s ease;
}

.btn-secondary {
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  color: #333;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #e0e0e0;
  border-color: #c9c9c9;
  color: #333;
}

.btn-outline-secondary {
  color: #333;
  border-color: #d9d9d9;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  color: #333;
}

.task-management-btn {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #333333;
  transition: all 0.3s ease;
}

.task-management-btn:hover,
.task-management-btn:focus {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: #333333;
}

.task-management-btn-active {
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  color: #333333;
}

.task-management-btn-active:hover,
.task-management-btn-active:focus {
  background-color: #e8e8e8;
  border-color: #d9d9d9;
  color: #333333;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.track-management {
  padding: 20px;
}

.modern-tabs {
  margin-top: -20px;
}

.modern-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.modern-tabs .ant-tabs-tab {
  padding: 12px 24px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.modern-tabs .ant-tabs-tab-active {
  background-color: #f0f2f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modern-tabs .ant-tabs-content-holder {
  background-color: #f0f2f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 24px;
}

.dashboard-card,
.assigned-jobs-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.ant-table-wrapper {
  background: white;
  border-radius: 8px;
}

.track-info-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: #f0f5ff;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.track-info-card .ant-card-body {
  padding: 12px;
}

.track-info-card h3 {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
}

.track-info-card p {
  margin-bottom: 0;
  font-size: 14px;
  color: #666;
}

.station-card .ant-table-thead > tr > th {
  background-color: #f0f2f5;
}

.station-card .ant-table-tbody > tr > td {
  padding: 8px 16px;
}

.cost-status-card .ant-statistic,
.clock-status-card .ant-statistic {
  margin-bottom: 16px;
}

.dashboard-card .ant-card {
  height: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modern-tabs .ant-tabs-tab {
    padding: 8px 16px;
    font-size: 14px;
  }

  .modern-tabs .ant-tabs-content-holder {
    padding: 16px;
  }
}

.track-management .ant-card {
  height: 100%;
}

.station-card .ant-table-body {
  max-height: 240px;
  overflow-y: auto;
}

.cost-status-card .ant-statistic,
.clock-status-card .ant-statistic {
  margin-bottom: 16px;
}

@media (max-width: 576px) {
  .ant-statistic-title {
    font-size: 12px;
  }

  .ant-statistic-content {
    font-size: 16px;
  }
}
